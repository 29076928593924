var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.variation
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-2" },
            [
              _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.translate(_vm.variation.country.name)) +
                      " / " +
                      _vm._s(_vm.translate(_vm.variation.sector.name))
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.download()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("fa fa-download"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("actions.download")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.loading
            ? _c("Data", {
                attrs: { data: _vm.data, config: "", lists: _vm.lists },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }